import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useEffect, type FC } from 'react'
import { Notify } from './lib/notify'
import { Confirm } from './lib/confirm'
import Loading from './components/Loading'
import DashboardPage from './pages/dashboard'
import AnalyticsDashboardPage from './pages/dashboard/analytics/top'
import EventAnalyticsDashboardPage from './pages/dashboard/analytics/event'
import SettingPage from './pages/setting'
import TopPage from './pages/TopPage'
import SettingAccountPage from './pages/setting/SettingAccountPage'
import Privacy from './pages/Privacy'
import { useMyProfileForRoot } from './hooks/useMyProfile'
import { useDeviceAndDeviceGroups } from './hooks/useDeviceAndDeviceGroups'
import { useNotificationsForRoot } from './hooks/useNotifications'

export default function App() {
  const { isLoading, isAuthenticated } = useAuth0()
  const { initMyProfile, initMyActcastDeviceGroups } = useMyProfileForRoot()
  const { fetchNotifications } = useNotificationsForRoot()

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return
    }
    initMyProfile()
    initMyActcastDeviceGroups()
    fetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated])

  return (
    <Router>
      <Routes>
        <Route index element={<TopPageRoute />} />
        <Route path="app/*" element={<AuthRoute />} />
        <Route path="callback/" element={<Callback />} />
        <Route path="privacy/" element={<Privacy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Notify />
      <Confirm />
    </Router>
  )
}

function Callback() {
  return <Navigate to="/app/" />
}

// withAuthenticationRequiredの型でうまくいかないので仕方なく as FC
// https://github.com/vercel/next.js/discussions/67365

function TopPageRoute() {
  const Component = withAuthenticationRequired(() => {
    const { isLoading } = useAuth0()
    const { initializedActcastDevceGroup, actcastDeviceGroups } = useDeviceAndDeviceGroups()

    if (isLoading) {
      return <Loading />
    }

    if (initializedActcastDevceGroup && actcastDeviceGroups.ids.length > 0) {
      return <Navigate to="/app/" />
    }

    return <TopPage />
  }) as FC

  return <Component />
}

function AuthRoute() {
  const Component = withAuthenticationRequired(() => {
    const { isLoading } = useAuth0()

    if (isLoading) {
      return <Loading />
    }

    return (
      <Routes>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="dashboard/analytics/*">
          <Route path="event" element={<EventAnalyticsDashboardPage />} />
          <Route path="*" element={<AnalyticsDashboardPage />} />
        </Route>
        <Route path="setting/*" element={<SettingPage />}>
          <Route path="account" element={<SettingAccountPage />} />
          <Route path="*" element={<Navigate to="account" />} />
        </Route>
        <Route path="*" element={<Navigate to="dashboard" />} />
      </Routes>
    )
  }) as FC

  return <Component />
}
