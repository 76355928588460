import { useAtom, useAtomValue } from 'jotai'
import { useAuth0 } from '@auth0/auth0-react'
import { actcastDeviceGroupsState } from './jotai/deviceGrups'
import { deviceState } from './jotai/devices'
import { useFetchDevicesFromGroupsBase } from './jotai/devices'
import { getAPIAccessToken } from '../lib/auth0'

export const useAnalytics = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [actcastDeviceGroups, setActcastDeviceGroups] = useAtom(actcastDeviceGroupsState)
  const { fetchDevicesFromGroupsBase } = useFetchDevicesFromGroupsBase()
  const devices = useAtomValue(deviceState)

  return {
    state: {
      actcastDeviceGroups,
      devices
    },
    setCurrentActcastDeviceGroupId: async (actcastDeviceGroupId: string) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const devices = await fetchDevicesFromGroupsBase({
        actcastDeviceGroupId,
        accessToken
      })
      setActcastDeviceGroups({ ...actcastDeviceGroups, currentActcastDeviceGroupId: actcastDeviceGroupId })

      return { devices }
    }
  }
}
