import { type API } from 'client-shared/api/universal'
import { useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import { useAuth0 } from '@auth0/auth0-react'
import { useAnalytics } from '../../../../hooks/useAnalytics'
import { getAPIAccessToken } from '../../../../lib/auth0'
import { clients } from '../../../../lib/clients'

type Event = ReturnType<API['/api/analytics/event/device/:deviceId']['GET']['response'][200]['body']>

type EventsState = {
  events: {
    // key: deviceId
    [key: string]: Event
  }
}

export const eventsState = atom<EventsState>({
  events: {}
})

export const useEventAnalytics = () => {
  const { state, setCurrentActcastDeviceGroupId } = useAnalytics()
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [events, setEvents] = useAtom(eventsState)

  useEffect(() => {
    if (!state.actcastDeviceGroups.initializedFlag) {
      return
    }

    const group =
      state.actcastDeviceGroups.actcastDeviceGroups.data[state.actcastDeviceGroups.currentActcastDeviceGroupId]
    const promises = []
    for (const deviceId of group.actcastDevices) {
      promises.push(fetchEvents({ deviceId }))
    }
    Promise.all(promises)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.actcastDeviceGroups.initializedFlag])

  async function fetchEvents(options: { deviceId: string }) {
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

    const res = await clients['/api/analytics/event/device/:deviceId'].GET.client({
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        deviceId: options.deviceId
      }
    })

    if (res.status === 200) {
      setEvents((prev) => {
        const data = { ...prev.events, [options.deviceId]: res.body }
        return {
          ...prev,
          events: data
        }
      })
    }

    return res
  }

  return {
    state: {
      ...state,
      events: events.events
    },
    setCurrentActcastDeviceGroupId: async (groupId: string) => {
      const { devices } = await setCurrentActcastDeviceGroupId(groupId)
      const promises = []
      for (const device of devices.data.actcastDevices) {
        promises.push(fetchEvents({ deviceId: device._id }))
      }
      await Promise.all(promises)
    }
  } as const
}
