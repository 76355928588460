import { useAuth0 } from '@auth0/auth0-react'
import { atom, useAtom, useAtomValue } from 'jotai'
import { clients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0'

type Notification = Awaited<
  ReturnType<(typeof clients)['/api/notifications']['GET']['client']>
>['body']['notifications'][number]

export type NotificationState = {
  loading: boolean
  notifications: {
    ids: string[]
    data: Record<string, Notification>
  }
}

const notificationsState = atom<NotificationState>({
  loading: true,
  notifications: {
    ids: [],
    data: {}
  }
})

export const useNotifications = () => {
  const notifications = useAtomValue(notificationsState)

  return {
    notifications
  } as const
}

export const useNotificationsForRoot = () => {
  const [notifications, setNotifications] = useAtom(notificationsState)
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()

  const fetchNotifications = async () => {
    if (!user) {
      return
    }
    setNotifications({ loading: true, notifications: { ids: [], data: {} } })
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await clients['/api/notifications'].GET.client({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const notifications: NotificationState['notifications'] = {
      ids: [],
      data: {}
    }
    for (const notification of res.body.notifications) {
      const id = notification._id
      notifications.ids.push(id)
      notifications.data[id] = notification
    }

    setNotifications({ loading: false, notifications })

    return res
  }

  return {
    notifications,
    fetchNotifications
  } as const
}
