import type { clients } from '../../lib/clients'
import { atom } from 'jotai'

type ActcastDeviceGroup = Awaited<
  ReturnType<(typeof clients)['/api/@me/actcastDeviceGroups']['GET']['client']>
>['body']['actcastDeviceGroups'][number]

export type ActcastDeviceGroupsState = {
  currentActcastDeviceGroupId: string
  loading: boolean
  initializedFlag: boolean
  actcastDeviceGroups: {
    ids: string[]
    data: {
      [key: string]: ActcastDeviceGroup
    }
  }
}

export const actcastDeviceGroupsState = atom<ActcastDeviceGroupsState>({
  currentActcastDeviceGroupId: '',
  loading: false,
  initializedFlag: false,
  actcastDeviceGroups: {
    ids: [],
    data: {}
  }
})
