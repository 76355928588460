import { atom, useAtom, useAtomValue } from 'jotai'
import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { clients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0'
import { deviceState } from './jotai/devices'
import { actcastDeviceGroupsState } from './jotai/deviceGrups'

type ListPhotos = Awaited<ReturnType<(typeof clients)['/api/photo/:deviceId/list']['GET']['client']>>['body']['files']

export type LatestPhotoType = {
  url: string
  taken_at: string
}

export type RequestTakePhotoStatus = {
  requesting: boolean
}

const latestPhotoMapState = atom<{
  [deviceId: string]: LatestPhotoType
}>({})

const requestTakePhotoState = atom<{ requesting: boolean }>({
  requesting: false
})

const listPhotoMapState = atom<{ [id: string]: ListPhotos }>({})

export function useActcast() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const actcastDeviceGroups = useAtomValue(actcastDeviceGroupsState)
  const devices = useAtomValue(deviceState)
  const [latestPhotoMap, setLatestPhotos] = useAtom(latestPhotoMapState)
  const [requestTakePhotoStatus, setRequestTakePhotoStatus] = useAtom(requestTakePhotoState)
  const [listPhotoMap, setListPhotoMap] = useAtom(listPhotoMapState)

  const fetchListPhoto = useCallback(
    async (deviceId: string) => {
      try {
        const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
        const res = await clients['/api/photo/:deviceId/list'].GET.client({
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { deviceId }
        })
        setListPhotoMap((before) => {
          return { ...before, [deviceId]: res.body.files }
        })
        return res.body
      } catch (e) {
        // TODO
      }
    },
    [getAccessTokenSilently, getAccessTokenWithPopup, setListPhotoMap]
  )

  const fetchLatestPhoto = useCallback(
    async (deviceId: string) => {
      try {
        const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
        const res = await clients['/api/photo/:deviceId/latest'].GET.client({
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { deviceId }
        })
        setLatestPhotos((before) => {
          return { ...before, [deviceId]: { url: res.body.url, taken_at: res.body.taken_at } }
        })
        return
      } catch (e) {
        // TODO
      }
    },
    [getAccessTokenSilently, getAccessTokenWithPopup, setLatestPhotos]
  )

  const requestTakePhoto = useCallback(
    async (deviceId: string) => {
      if (!deviceId) {
        return
      }
      setRequestTakePhotoStatus({ requesting: true })
      try {
        const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
        await clients['/api/photo/:deviceId/request'].GET.client({
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { deviceId }
        })
      } catch (e) {
        // TODO
      }
      setRequestTakePhotoStatus({ requesting: false })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  return {
    state: {
      actcastDeviceGroups,
      devices,
      requestTakePhotoStatus,
      latestPhotoMap,
      listPhotoMap
    },
    fetchLatestPhoto,
    requestTakePhoto,
    fetchListPhoto
  }
}
