import type { clients } from '../lib/clients'
import { atom, useAtom } from 'jotai'

type Device = Awaited<
  ReturnType<(typeof clients)['/api/actcastDeviceGroups/:groupId/devices']['GET']['client']>
>['body']['actcastDevices'][number]

export type DevicePhotoDialogData = Pick<Device, 'name' | 'foundness' | 'actcastId'>

type DevicePhotoDialog = {
  open: boolean
}

export const devicePhotoDialogDataState = atom<DevicePhotoDialogData>({
  name: '',
  foundness: 'NotFound',
  actcastId: ''
})

export const devicePhotoDialogState = atom<DevicePhotoDialog>({
  open: false
})

export function useDevicePhotoViewer() {
  const [dialogState, setDialogState] = useAtom(devicePhotoDialogState)
  const [devicePhotoDialog, setDevicePhotoDialog] = useAtom(devicePhotoDialogDataState)

  return {
    state: {
      open: dialogState.open,
      devicePhotoDialog
    },
    openDialog: (data: DevicePhotoDialogData) => {
      setDevicePhotoDialog(data)
      setDialogState({ open: true })
    },
    closeDialog: () => {
      setDialogState({ open: false })
      setDevicePhotoDialog({ name: '', foundness: 'NotFound', actcastId: '' })
    }
  } as const
}
